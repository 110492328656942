module.exports = {
  tableHeaders: {
    new_sale: [
      {
        text: 'Nome',
        value: 'name',
        align: 'center'
      },
      {
        text: 'Valor unitário',
        value: 'unit_value',
        align: 'center'
      },
      {
        text: 'Quantidade',
        value: 'quantity',
        align: 'center'
      },
      {
        text: 'Tipo de cobrança',
        value: 'charge_type',
        align: 'center'
      },
      {
        text: 'Acumulativo',
        value: 'is_accumulative',
        align: 'center'
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center'
      },
      {
        text: 'Valor da implantação',
        value: 'implantation_value',
        align: 'center'
      },
      {
        text: 'Faturamento Imediato',
        value: 'immediate_billing',
        align: 'center'
      },
      {
        text: 'Data de faturamento',
        value: 'billing_date_periods',
        align: 'center'
      },
      {
        text: 'Valor total',
        value: 'total_value',
        align: 'center'
      },
      {
        text: 'Ambev - Meses',
        value: 'ambev_incentive_months',
        align: 'center'
      },
      {
        text: 'Ambev - Texto',
        value: 'ambev_incentive_text',
        align: 'center'
      }
    ],
    temporary_churn: [
      {
        text: 'Produto',
        value: 'type',
        align: 'center'
      },
      {
        text: 'Valor Unitário Abonado',
        value: 'unit_value',
        align: 'center'
      },
      {
        text: 'Quantidade Abonada',
        value: 'quantity',
        align: 'center'
      },
      {
        text: 'Total Abonado',
        value: 'total_value',
        align: 'center'
      }
    ],
    revert_temporary_churn: [
      {
        text: 'Nome Produto',
        value: 'name',
        align: 'center'
      },
      {
        text: 'Valor Unitário Abonado',
        value: 'unit_value',
        align: 'center'
      },
      {
        text: 'Quantidade Abonada',
        value: 'quantity',
        align: 'center'
      },
      {
        text: 'Total Abonado',
        value: 'total_value',
        align: 'center'
      }
    ],
    downsell: [
      {
        text: 'Id da venda de origem',
        value: 'sale_id',
        align: 'center'
      },
      {
        text: 'Nome do produto',
        value: 'type'
      },
      {
        text: 'Quantidade',
        value: 'quantity'
      },
      {
        text: 'Incentivo Ambev',
        value: 'ambev_incentive',
        align: 'center'
      },
      {
        text: '',
        value: 'options',
        align: 'center'
      }
    ]
  },
  modalTitles: {
    new_sale: 'Venda',
    temporary_churn: 'Abono Temporário',
    revert_temporary_churn: 'Retorno Abono Temporário',
    discount: 'Renegociação de MRR',
    cross_sell: 'Cross Sell',
    upsell: 'Upsell',
    downsell: 'Downsell'
  }
}
